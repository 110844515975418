import React, { useEffect } from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAChip, DNADivider, DNAIcon, DNAText, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { useContent } from 'src/state/context/ContentProvider/ContentProvider.proxy';
import { ORMTypes, isDocumentVersionORM, isFolderItemORM, isPageGroupORM } from 'src/types/types';
import { FileType, Sentiment } from '@alucio/aws-beacon-amplify/src/models';
import DNAActionBar from 'src/components/DNA/ActionBar/DNAActionBar';
import SlideRollWrapper from 'src/screens/Meetings/SharedComponents/SlideRollWrapper';
import PresentationNav from 'src/screens/Meetings/SharedComponents/PresentationNav';
import { useMeetingsState } from 'src/state/context/Meetings/MeetingsStateProvider.proxy';
import PlayerWrapper from 'src/components/Presentation/PlayerWrapper';
import TextSearchPanel from 'src/components/TextSearchPanel/TextSearchPanel';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import { DNALoaderEvents } from 'src/components/DNA/Loader/DNALoader';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { useHighlighter } from 'src/components/Highlighter/HighlighterProvider';
import AllNotes from 'src/screens/Meetings/SharedComponents/Notes/AllNotes';

/** ANIMATION IMPORTS */
import LottieAnimation from 'react-lottie-player';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { ActionBarState } from 'src/screens/Meetings/SharedComponents/PresentationMenu';
import { CanvasVariantEnum } from 'src/components/Highlighter/UseCanvas';
const PopupHiddenAnimation = require('src/assets/lottie/shared-window-visibility-animation.json')

const S = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors['color-gray-800'],
  },
  backStyle: {
    marginLeft: 4,
  },
  iframeContainer: {
    borderColor: colors['color-success-500'],
    borderWidth: 4,
  },
  slideRollContainer: {
    borderRightWidth: 1,
    borderColor: '#383838',
  },
});

const Presenting = () => {
  const { activePresentation, isCustomDeck } = useContent()
  const {
    meetingORM,
    slideRollVisible,
    toggleSlideRollVisibility,
    textSearchVisible,
    setTextSearchVisible,
    toggleTextSearchVisibility,
    popoutContentHidden,
    openNotes,
    focusPopoutWindow,
    notesPanelVisible,
    isMainPlayer,
    toggleAllNotes,
    updateReaction,
    updateFollowUp,
    presentedMeta,
    actionBarState,
    setPresentationMenu,
    canEndMeeting,
    endMeeting,
  } = useMeetingsState();
  const featureFlags = useFeatureFlags(
    'enableMeetingReactions',
    'enableMeetingFollowUp',
  );

  const {
    setHighlighterMode,
    selectedType,
    setHighlighterVisible,
  } = useHighlighter();

  useEffect(() => {
    // WHEN SWITCHING DOCUMENT, RESET THE HIGHLIGHTER MODE
    setHighlighterVisible(false)
    setPresentationMenu(ActionBarState.PresentationMenu)
  }, [activePresentation?.presentable.id])

  if (!meetingORM) return null

  let orm;
  if (isDocumentVersionORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm
  } else if (isFolderItemORM(activePresentation?.presentable.orm)) {
    orm = activePresentation?.presentable.orm.relations.itemORM
  }
  const isWebDoc = orm?.model.type === FileType.WEB
  const isVideoDoc = orm?.model.type === FileType.MP4
  const isHTMLDoc = orm?.model.type === FileType.HTML
  const disablePresentationNav = isWebDoc || isVideoDoc || isHTMLDoc
  const displayActionBar =
    isMainPlayer && activePresentation && !isWebDoc && actionBarState === ActionBarState.PresentationMenu;

  const renderActivePresentationInfo = () => {
    if (!activePresentation) return null

    const { orm, title } = activePresentation.presentable

    const isDocumentVersion =
      !isPageGroupORM(orm) &&
      (isDocumentVersionORM(orm) ||
        isDocumentVersionORM(orm.relations.itemORM));
    const isModifiedDocumentVersion =
      isFolderItemORM(orm) &&
      isDocumentVersion &&
      orm.model.visiblePages?.length;
    const showModifiedBadge =
      isModifiedDocumentVersion ||
      !isDocumentVersion;
    const isCustomDeck = isFolderItemORM(activePresentation?.presentable.orm)
      ? activePresentation?.presentable.orm.model.type === ORMTypes.CUSTOM_DECK : false

    // @ts-ignore can be ignored here since we are checking and making sure it is customDeck
    const isWithinGracePeriod = isCustomDeck && orm.relations.itemORM.meta.version.withinGracePeriod

    return (
      <DNABox fill>
        <Iffy is={isWithinGracePeriod}>
          <DNABox style={{ marginRight: 12 }} >
            <DNAIcon.Styled
              appearance="ghost"
              status="warning"
              size="md"
              name="alert"
            />
            <DNAText status="warning" style={{ paddingLeft: 4 }}>
              Needs review
            </DNAText>
          </DNABox>
        </Iffy>
        <DNABox style={{ marginRight: 12 }}>
          { /* DOCUMENT VERSION BADGE */}
          {isDocumentVersion &&
            <CustomFieldBadgeList documentVersionORM={activePresentation.currentPresentablePage.documentVersionORM} />
          }

          { /* MODIFIED BADGE */}
          {showModifiedBadge &&
            <DNAChip appearance="tag">
              MODIFIED
            </DNAChip>
          }

        </DNABox>
        <DNABox fill style={{ marginRight: 12 }}>
          <DNAText numberOfLines={1} status="basic">
            {title}
          </DNAText>
        </DNABox>
      </DNABox>
    )
  }

  const actionBarItemDefaults: DNAButtonProps = {
    appearance: 'filled',
    status: 'dark',
    size: 'xs',
  }

  return (
    <DNABox fill appearance="col" style={S.contentContainer}>

      {/* HEADER */}
      <Iffy is={isMainPlayer}>
        <DNABox fill alignY="center" alignX="end" style={{ padding: 16, maxHeight: 56 }}>
          {renderActivePresentationInfo()}
          <DNAButton
            size="xs"
            status="danger"
            onPress={endMeeting}
            disabled={!canEndMeeting}
          >
            <DNAText status="basic">End</DNAText>
          </DNAButton>
        </DNABox>
      </Iffy>
      <DNADivider variant="virtual" />

      {/* CONTENT */}
      <DNABox testID="presentation-content" fill>

        {textSearchVisible &&
          <TextSearchPanel
            onClose={() => { setTextSearchVisible(false) }}
            contentHookVariant="proxied"
          />
        }

        {/* SLIDE ROLL */}
        <Iffy is={activePresentation && isMainPlayer}>

          <DNABox
            style={[S.slideRollContainer, !slideRollVisible && { width: 0, height: 0, display: 'none' }]}
          >
            <SlideRollWrapper closeButtonOnPress={toggleSlideRollVisibility} />
          </DNABox>

        </Iffy>
        <DNABox appearance="col" fill>

          {/* PRESENTATION */}

          <DNABox style={isMainPlayer && S.iframeContainer} fill>

            {/* IFRAME */}
            { activePresentation || !isMainPlayer
              ? <PlayerWrapper.Highlighter
                  isFullWindow={true}
                  meetingId={meetingORM.model.id}
                  mode={isMainPlayer ? 'INTERACTIVE' : 'NON_INTERACTIVE'}
                  isFromMeetings={true}
                  highlighterVisible
                  analyticsEventType={isMainPlayer ? DNALoaderEvents.MEETING : undefined}
                  isCustomDeck={isCustomDeck}
              />
              : <ImageBackground
                  source={require('../../../../assets/images/beacon-presentation-bg.jpg')}
                  style={{ flex: 1 }}
              />
            }

            {/* Content not visible overlay */}

            {/* NOTE: Can't use stack here because the playerwrapper esplodes 🤯 */}
            {popoutContentHidden &&
              <DNABox
                fill
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }}
                alignX="center"
                alignY="center"
                appearance="col"
                spacing="lg"
              >
                <DNAText h1 bold status="basic">Adjust windows so shared window is visible</DNAText>
                <LottieAnimation
                  play
                  animationData={PopupHiddenAnimation}
                />
              </DNABox>}
          </DNABox>

          {/* web doc will not have the actionBar, but video for now will have the highlighter */}
          <Iffy is={displayActionBar}>

            {/* ACTION BAR */}
            <DNABox alignY="center" alignX="center" spacing="sm" style={{ padding: 12 }}>
              {/* SLIDE NAVIGATION */}
              {/* << >> prev and next pages are not able for web and video docs */}
              {!disablePresentationNav && <PresentationNav />}
              <DNAActionBar
                spacing="sm"
                itemDefaults={actionBarItemDefaults}
              >

                {/* Slideroll button */}
                <DNAActionBar.Item
                  hidden={isVideoDoc}
                  onPress={toggleSlideRollVisibility}
                  key="Slides"
                  testID="toggle-slides-button"
                  status={slideRollVisible ? 'primary' : 'dark'}
                  padding="sm"
                >
                  <DNAText status="basic">Slides</DNAText>
                </DNAActionBar.Item>

                {/* All notes panel button */}
                <DNAActionBar.Item
                  key="Notes"
                  testID="toggle-speaker-notes-button"
                  onPress={toggleAllNotes}
                  status={notesPanelVisible ? 'primary' : 'dark'}
                  padding="sm"
                >
                  <DNAText status="basic">Notes</DNAText>
                </DNAActionBar.Item>

                {/* Highlight Button */}
                <DNAActionBar.Item
                  onPress={() => setPresentationMenu(ActionBarState.HighlighterMenu)}
                  key="Marker"
                  testID="toggle-marker-button"
                  status={actionBarItemDefaults.status}
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                  padding="sm"
                >
                  <DNAIcon.Styled name="marker"/>
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Highlighter</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Text Search Button */}
                <DNAActionBar.Item
                  hidden={isVideoDoc}
                  onPress={toggleTextSearchVisibility}
                  key="Text Search"
                  testID="toggle-text-search"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                  status={textSearchVisible ? 'primary' : 'dark'}
                  padding="sm"
                >
                  <DNAIcon.Styled name="magnify"/>
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Search</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Positive Sentiment Button */}
                <DNAActionBar.Item
                  hidden={!featureFlags.enableMeetingReactions}
                  onPress={() => updateReaction(Sentiment.POSITIVE)}
                  key="Positive sentiment"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                  padding="sm"
                >
                  <DNAIcon.Styled
                    appearance={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'ghostLink' : undefined}
                    status={presentedMeta?.sentiment === Sentiment.POSITIVE ? 'success' : undefined }
                    name="chevron-up"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Positive reaction</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Negative Sentiment Button */}
                <DNAActionBar.Item
                  hidden={!featureFlags.enableMeetingReactions}
                  onPress={() => updateReaction(Sentiment.NEGATIVE)}
                  key="Negative sentiment"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                  padding="sm"
                >
                  <DNAIcon.Styled
                    appearance={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'ghostLink' : undefined}
                    status={presentedMeta?.sentiment === Sentiment.NEGATIVE ? 'warning' : undefined}
                    name="chevron-down"
                  />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Negative reaction</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>
                {/* Follow-up Button */}
                <DNAActionBar.Item
                  hidden={!featureFlags.enableMeetingFollowUp}
                  onPress={() => updateFollowUp()}
                  key="Flag for follow-up"
                  _popoverProps={{
                    type: 'tooltip',
                    placement: 'top',
                  }}
                  padding="sm"
                >
                  <DNAIcon.Styled name={presentedMeta?.followUp ? 'flag' : 'flag-outline'} />
                  <DNAActionBar.Item.PopoverContent>
                    <DNABox>
                      <DNAText status="basic">Flag for follow-up</DNAText>
                    </DNABox>
                  </DNAActionBar.Item.PopoverContent>
                </DNAActionBar.Item>

              </DNAActionBar>
            </DNABox>
          </Iffy>

          {/* Focus Popout Window */}
          <Iffy is={!isMainPlayer}>
            <DNABox
              style={{
                bottom: 5,
                right: 5,
                position: 'absolute',
              }}
            >
              <DNAButton
                size="md"
                status="dark"
                padding="sm"
                onPress={focusPopoutWindow}
                testID="focus-popout-window"
                iconRight="book-open-outline"
                style={{ paddingHorizontal: 0 }}
              />
            </DNABox>
          </Iffy>

          {/* Highligther Bar */}
          <Iffy is={actionBarState === ActionBarState.HighlighterMenu}>
            <DNABox alignY="center" alignX="center" spacing="sm" style={{ padding: 12 }}>
              <DNAActionBar
                spacing="sm"
                itemDefaults={actionBarItemDefaults}
              >
                <DNAActionBar.Item
                  onPress={() => {
                    setPresentationMenu(ActionBarState.PresentationMenu)
                    setHighlighterVisible(false)
                  }}
                  testID="back-btn"
                >
                  <DNAIcon.Styled name="arrow-left-bold"/>
                  <DNABox style={S.backStyle}>
                    <DNAText status="basic">Back</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
                <DNAActionBar.Item
                  onPress={() => setHighlighterMode(CanvasVariantEnum.arrow)}
                  testID="arrow-btn"
                  status={selectedType === CanvasVariantEnum.arrow ? 'info' : 'dark'}
                >
                  <DNAIcon.Styled name="arrow-top-right"/>
                  <DNABox style={{ marginLeft: 4 }}>
                    <DNAText status="basic">Arrow</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
                <DNAActionBar.Item
                  testID="box-btn"
                  onPress={() => setHighlighterMode(CanvasVariantEnum.square)}
                  status={selectedType === CanvasVariantEnum.square ? 'info' : 'dark'}
                >
                  <DNAIcon.Styled
                    name="checkbox-blank"
                  />
                  <DNABox style={{ marginLeft: 4 }}>
                    <DNAText status="basic">Box highlighter</DNAText>
                  </DNABox>
                </DNAActionBar.Item>
              </DNAActionBar>
            </DNABox>
          </Iffy>
        </DNABox>

        {/* SPEAKER/MY NOTES */}
        <AllNotes openNotes={openNotes}/>
      </DNABox>
    </DNABox>
  );
};

Presenting.displayName = 'Presenting';

export default Presenting;
