/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      defaultFilterValues {
        key
        values
      }
      lockedFilters {
        key
        values
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
      }
      isExcludedFromReporting
      additionalRoles
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoSubId
        tenantId
        email
        givenName
        phoneNumber
        familyName
        meetingId
        defaultFiltersCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        lockedFiltersCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        defaultFilterValues {
          key
          values
        }
        lockedFilters {
          key
          values
        }
        shareBCC
        shareCC
        createdAt
        createdBy
        updatedAt
        status
        role
        bookmarkedDocs {
          docID
          createdAt
        }
        isExcludedFromReporting
        additionalRoles
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        cognitoSubId
        tenantId
        email
        givenName
        phoneNumber
        familyName
        meetingId
        defaultFiltersCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        lockedFiltersCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        defaultFilterValues {
          key
          values
        }
        lockedFilters {
          key
          values
        }
        shareBCC
        shareCC
        createdAt
        createdBy
        updatedAt
        status
        role
        bookmarkedDocs {
          docID
          createdAt
        }
        isExcludedFromReporting
        additionalRoles
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
        }
        sort {
          field
          isAsc
        }
      }
      featureFlags {
        flagId
        value
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
          }
          status
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
          }
          createdAt
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
          }
          usage
          documentSettings {
            fieldPosition
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
              }
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
            }
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
        }
        mslListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        publisherListConfig {
          sort {
            field
            isAsc
          }
          fields {
            field
            width
          }
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
              }
            }
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
              }
              apiName
            }
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
              }
            }
            type
            parents {
              apiName
              relationshipName
            }
            usage
          }
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
        }
        hubsConfig {
          disabledSections
          disableNotationDescription
        }
        reports {
          key
          value
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        licensedUsers
        status
        fields {
          fieldName
          description
          required
          isEmailTemplateFilter
          dataType
          userFilter
          defaultSearchFilter
          values
        }
        folderUpdateGracePeriodDays
        medInfoURL
        publisherListConfig {
          fields {
            fieldName
            header
            width
          }
          sort {
            field
            isAsc
          }
        }
        mslListConfig {
          fields {
            fieldName
            header
            width
          }
          sort {
            field
            isAsc
          }
        }
        featureFlags {
          flagId
          value
        }
        createdAt
        createdBy
        updatedAt
        statusChangedAt
        statusChangedBy
        ssoDomains
        integrations
        config {
          requiredSlidesHiddenMessage
          forms {
            id
            name
            label
            fields {
              id
              fieldName
              fieldLabel
              reportingName
              status
              description
              required
              maxLength
              formatValidation
              fieldType
              showByDefault
              dependentFieldId
              controlType
              order
              settings {
                isBadge
                fieldPosition
              }
              usage
              documentSettings {
                fieldPosition
              }
              fieldValueDefinitions {
                id
                value
                label
                disabled
                isDefault
                documentSettings {
                  presentationWatermarkText
                  permission {
                    present
                    modify
                    share
                    download
                    externalNotation
                  }
                }
                badgeColor
                badgeLabel
                createdAt
                dependentValueIds
              }
              displayOnParentSelection
              displayOnValueSelection
              objectSetting {
                childrenFieldIds
                restriction {
                  syncUpdate
                  syncDelete
                  update
                  delete
                }
              }
              isChildField
              dateRestriction
              createdAt
              scaleNumber
              precisionNumber
            }
            targetRequestTemplate
            targetType
            targetAddress
            targetParameters {
              key
              value
            }
            status
          }
          meetingFields {
            id
            fieldName
            status
            description
            required
            maxLength
            type
            controlType
            fieldValueDefinitions {
              id
              value
              disabled
              createdAt
            }
            createdAt
          }
          customFields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          defaultDocumentPermissions {
            present
            modify
            share
            download
            externalNotation
          }
          mslListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
          publisherListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
          crmIntegration {
            id
            crmIntegrationType
            instanceUrl
            clientId
            redirectUri
            name
            accountsSettings {
              query
              displaySettings {
                shortened
                extended
              }
              addressSettings {
                alias
                displaySettings {
                  shortened
                  extended
                }
              }
            }
            meetingSetting {
              apiName
              presentationsFieldName
              children {
                name
                apiName
                relationshipName
                type
                veevaSetting {
                  markerFieldName
                }
              }
              type
            }
            additionalSettings {
              allowCommentsOnTasks
              detailContentTableSettings {
                name
                apiName
                relationshipName
                prefix
              }
              enableSurveys
              crmAccountToObjectSettings {
                accountFieldNames {
                  accountFieldName
                  objectFieldName
                }
                apiName
              }
            }
            loginUrl
            crmStandaloneForms {
              id
              apiName
              children {
                name
                apiName
                relationshipName
                type
                veevaSetting {
                  markerFieldName
                }
              }
              type
              parents {
                apiName
                relationshipName
              }
              usage
            }
          }
          sessionTimeout
          quickFilters {
            id
            label
            path
          }
          hubsConfig {
            disabledSections
            disableNotationDescription
          }
          reports {
            key
            value
          }
          emailDocumentUpdateFrequency
          sharePPTXasPDF
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTenants = /* GraphQL */ `
  query SyncTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTenants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        licensedUsers
        status
        fields {
          fieldName
          description
          required
          isEmailTemplateFilter
          dataType
          userFilter
          defaultSearchFilter
          values
        }
        folderUpdateGracePeriodDays
        medInfoURL
        publisherListConfig {
          fields {
            fieldName
            header
            width
          }
          sort {
            field
            isAsc
          }
        }
        mslListConfig {
          fields {
            fieldName
            header
            width
          }
          sort {
            field
            isAsc
          }
        }
        featureFlags {
          flagId
          value
        }
        createdAt
        createdBy
        updatedAt
        statusChangedAt
        statusChangedBy
        ssoDomains
        integrations
        config {
          requiredSlidesHiddenMessage
          forms {
            id
            name
            label
            fields {
              id
              fieldName
              fieldLabel
              reportingName
              status
              description
              required
              maxLength
              formatValidation
              fieldType
              showByDefault
              dependentFieldId
              controlType
              order
              settings {
                isBadge
                fieldPosition
              }
              usage
              documentSettings {
                fieldPosition
              }
              fieldValueDefinitions {
                id
                value
                label
                disabled
                isDefault
                documentSettings {
                  presentationWatermarkText
                  permission {
                    present
                    modify
                    share
                    download
                    externalNotation
                  }
                }
                badgeColor
                badgeLabel
                createdAt
                dependentValueIds
              }
              displayOnParentSelection
              displayOnValueSelection
              objectSetting {
                childrenFieldIds
                restriction {
                  syncUpdate
                  syncDelete
                  update
                  delete
                }
              }
              isChildField
              dateRestriction
              createdAt
              scaleNumber
              precisionNumber
            }
            targetRequestTemplate
            targetType
            targetAddress
            targetParameters {
              key
              value
            }
            status
          }
          meetingFields {
            id
            fieldName
            status
            description
            required
            maxLength
            type
            controlType
            fieldValueDefinitions {
              id
              value
              disabled
              createdAt
            }
            createdAt
          }
          customFields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
            }
            usage
            documentSettings {
              fieldPosition
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                }
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
              }
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
          }
          defaultDocumentPermissions {
            present
            modify
            share
            download
            externalNotation
          }
          mslListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
          publisherListConfig {
            sort {
              field
              isAsc
            }
            fields {
              field
              width
            }
          }
          crmIntegration {
            id
            crmIntegrationType
            instanceUrl
            clientId
            redirectUri
            name
            accountsSettings {
              query
              displaySettings {
                shortened
                extended
              }
              addressSettings {
                alias
                displaySettings {
                  shortened
                  extended
                }
              }
            }
            meetingSetting {
              apiName
              presentationsFieldName
              children {
                name
                apiName
                relationshipName
                type
                veevaSetting {
                  markerFieldName
                }
              }
              type
            }
            additionalSettings {
              allowCommentsOnTasks
              detailContentTableSettings {
                name
                apiName
                relationshipName
                prefix
              }
              enableSurveys
              crmAccountToObjectSettings {
                accountFieldNames {
                  accountFieldName
                  objectFieldName
                }
                apiName
              }
            }
            loginUrl
            crmStandaloneForms {
              id
              apiName
              children {
                name
                apiName
                relationshipName
                type
                veevaSetting {
                  markerFieldName
                }
              }
              type
              parents {
                apiName
                relationshipName
              }
              usage
            }
          }
          sessionTimeout
          quickFilters {
            id
            label
            path
          }
          hubsConfig {
            disabledSections
            disableNotationDescription
          }
          reports {
            key
            value
          }
          emailDocumentUpdateFrequency
          sharePPTXasPDF
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocumentVersion = /* GraphQL */ `
  query GetDocumentVersion($id: ID!) {
    getDocumentVersion(id: $id) {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocumentVersions = /* GraphQL */ `
  query ListDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        contentURL
        conversionStatus
        status
        srcFile {
          bucket
          region
          key
          url
        }
        srcHash
        srcSize
        numPages
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        type
        releaseNotes
        changeType
        labelValues {
          key
          value
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        hasCustomThumbnail
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        semVer {
          minor
          major
        }
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integration {
          externalVersionId
          version
          timestamp
          srcFileHash
          srcDocumentHash
        }
        integrationType
        conversionWarningCode
        conversionErrorCode
        scheduledPublish
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentVersions = /* GraphQL */ `
  query SyncDocumentVersions(
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        contentURL
        conversionStatus
        status
        srcFile {
          bucket
          region
          key
          url
        }
        srcHash
        srcSize
        numPages
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        type
        releaseNotes
        changeType
        labelValues {
          key
          value
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        hasCustomThumbnail
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        semVer {
          minor
          major
        }
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integration {
          externalVersionId
          version
          timestamp
          srcFileHash
          srcDocumentHash
        }
        integrationType
        conversionWarningCode
        conversionErrorCode
        scheduledPublish
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const documentVersionsByTenantIdStatus = /* GraphQL */ `
  query DocumentVersionsByTenantIdStatus(
    $tenantId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentVersionsByTenantIdStatus(
      tenantId: $tenantId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        contentURL
        conversionStatus
        status
        srcFile {
          bucket
          region
          key
          url
        }
        srcHash
        srcSize
        numPages
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        type
        releaseNotes
        changeType
        labelValues {
          key
          value
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        hasCustomThumbnail
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        semVer {
          minor
          major
        }
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integration {
          externalVersionId
          version
          timestamp
          srcFileHash
          srcDocumentHash
        }
        integrationType
        conversionWarningCode
        conversionErrorCode
        scheduledPublish
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const documentVersionsByDocumentIdVersionNumber = /* GraphQL */ `
  query DocumentVersionsByDocumentIdVersionNumber(
    $documentId: ID!
    $versionNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentVersionsByDocumentIdVersionNumber(
      documentId: $documentId
      versionNumber: $versionNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        contentURL
        conversionStatus
        status
        srcFile {
          bucket
          region
          key
          url
        }
        srcHash
        srcSize
        numPages
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        type
        releaseNotes
        changeType
        labelValues {
          key
          value
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        hasCustomThumbnail
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        semVer {
          minor
          major
        }
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integration {
          externalVersionId
          version
          timestamp
          srcFileHash
          srcDocumentHash
        }
        integrationType
        conversionWarningCode
        conversionErrorCode
        scheduledPublish
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttachedFile = /* GraphQL */ `
  query GetAttachedFile($id: ID!) {
    getAttachedFile(id: $id) {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAttachedFiles = /* GraphQL */ `
  query ListAttachedFiles(
    $filter: ModelAttachedFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachedFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        tenantId
        documentId
        srcFile {
          bucket
          region
          key
          url
        }
        srcFileName
        srcHash
        srcSize
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttachedFiles = /* GraphQL */ `
  query SyncAttachedFiles(
    $filter: ModelAttachedFileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachedFiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        tenantId
        documentId
        srcFile {
          bucket
          region
          key
          url
        }
        srcFileName
        srcHash
        srcSize
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        accessLevel
        status
        owner
        expiresAt
        integration {
          integrationId
          externalId
          firstSync
          lastSync
        }
        integrationType
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocuments = /* GraphQL */ `
  query SyncDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        accessLevel
        status
        owner
        expiresAt
        integration {
          integrationId
          externalId
          firstSync
          lastSync
        }
        integrationType
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEmailTemplate = /* GraphQL */ `
  query GetEmailTemplate($id: ID!) {
    getEmailTemplate(id: $id) {
      id
      tenantId
      labelValues {
        key
        value
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEmailTemplates = /* GraphQL */ `
  query ListEmailTemplates(
    $filter: ModelEmailTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        labelValues {
          key
          value
        }
        customFilterValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        subject
        title
        cc
        bcc
        body
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        status
        editPermissions
        createdBy
        createdAt
        updatedBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEmailTemplates = /* GraphQL */ `
  query SyncEmailTemplates(
    $filter: ModelEmailTemplateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEmailTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        labelValues {
          key
          value
        }
        customFilterValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        subject
        title
        cc
        bcc
        body
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        status
        editPermissions
        createdBy
        createdAt
        updatedBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocumentSetting = /* GraphQL */ `
  query GetDocumentSetting($id: ID!) {
    getDocumentSetting(id: $id) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocumentSettings = /* GraphQL */ `
  query ListDocumentSettings(
    $filter: ModelDocumentSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        createdAt
        createdBy
        updatedAt
        updatedBy
        documentId
        documentVersionId
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentSettings = /* GraphQL */ `
  query SyncDocumentSettings(
    $filter: ModelDocumentSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        createdAt
        createdBy
        updatedAt
        updatedBy
        documentId
        documentVersionId
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserNotations = /* GraphQL */ `
  query GetUserNotations($id: ID!) {
    getUserNotations(id: $id) {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserNotations = /* GraphQL */ `
  query ListUserNotations(
    $filter: ModelUserNotationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        createdAt
        createdBy
        updatedAt
        updatedBy
        documentId
        documentVersionId
        customDeckId
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        status
        type
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserNotations = /* GraphQL */ `
  query SyncUserNotations(
    $filter: ModelUserNotationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserNotations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        createdAt
        createdBy
        updatedAt
        updatedBy
        documentId
        documentVersionId
        customDeckId
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        status
        type
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomDeck = /* GraphQL */ `
  query GetCustomDeck($id: ID!) {
    getCustomDeck(id: $id) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomDecks = /* GraphQL */ `
  query ListCustomDecks(
    $filter: ModelCustomDeckFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomDecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        createdBy
        autoUpdateAcknowledgedAt
        updatedAt
        updatedBy
        tenantId
        groups {
          id
          pages {
            pageId
            pageNumber
            documentVersionId
          }
          visible
          srcId
          docAccessLevel
          name
          locked
        }
        title
        editMutex {
          userId
          timeStarted
          lastSeenAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomDecks = /* GraphQL */ `
  query SyncCustomDecks(
    $filter: ModelCustomDeckFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomDecks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        createdAt
        createdBy
        autoUpdateAcknowledgedAt
        updatedAt
        updatedBy
        tenantId
        groups {
          id
          pages {
            pageId
            pageNumber
            documentVersionId
          }
          visible
          srcId
          docAccessLevel
          name
          locked
        }
        title
        editMutex {
          userId
          timeStarted
          lastSeenAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        targetLabels {
          key
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        isPinned
        status
        items {
          id
          type
          itemId
          status
          addedAt
          updateAcknowledgedAt
          itemLastUpdatedAt
          visiblePages
          customTitle
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        sharePermissions {
          id
          isDeleted
          type
          targetType
          targetUsername
          targetCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          targetLabels {
            key
            values
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          deletedBy
          deletedAt
        }
        shareStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFolders = /* GraphQL */ `
  query SyncFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        name
        isPinned
        status
        items {
          id
          type
          itemId
          status
          addedAt
          updateAcknowledgedAt
          itemLastUpdatedAt
          visiblePages
          customTitle
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        sharePermissions {
          id
          isDeleted
          type
          targetType
          targetUsername
          targetCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          targetLabels {
            key
            values
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          deletedBy
          deletedAt
        }
        shareStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const foldersByTenantStatusShared = /* GraphQL */ `
  query FoldersByTenantStatusShared(
    $tenantId: ID!
    $statusShareStatus: ModelFolderByTenantStatusSharedCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    foldersByTenantStatusShared(
      tenantId: $tenantId
      statusShareStatus: $statusShareStatus
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        isPinned
        status
        items {
          id
          type
          itemId
          status
          addedAt
          updateAcknowledgedAt
          itemLastUpdatedAt
          visiblePages
          customTitle
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        sharePermissions {
          id
          isDeleted
          type
          targetType
          targetUsername
          targetCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          targetLabels {
            key
            values
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          deletedBy
          deletedAt
        }
        shareStatus
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getContentShare = /* GraphQL */ `
  query GetContentShare($id: ID!) {
    getContentShare(id: $id) {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      revoked
      customDeckDependencies {
        versions
        folderId
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listContentShares = /* GraphQL */ `
  query ListContentShares(
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
        tenantId
        type
        contentId
        meetingId
        expiresAt
        createdAt
        updatedAt
        createdBy
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        revoked
        customDeckDependencies {
          versions
          folderId
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContentShares = /* GraphQL */ `
  query SyncContentShares(
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContentShares(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        token
        tenantId
        type
        contentId
        meetingId
        expiresAt
        createdAt
        updatedAt
        createdBy
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        revoked
        customDeckDependencies {
          versions
          folderId
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contentShareByToken = /* GraphQL */ `
  query ContentShareByToken(
    $token: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentShareByToken(
      token: $token
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        tenantId
        type
        contentId
        meetingId
        expiresAt
        createdAt
        updatedAt
        createdBy
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        revoked
        customDeckDependencies {
          versions
          folderId
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const contentShareByContentID = /* GraphQL */ `
  query ContentShareByContentID(
    $contentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContentShareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentShareByContentID(
      contentId: $contentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        tenantId
        type
        contentId
        meetingId
        expiresAt
        createdAt
        updatedAt
        createdBy
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        revoked
        customDeckDependencies {
          versions
          folderId
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationLog = /* GraphQL */ `
  query GetIntegrationLog($id: ID!) {
    getIntegrationLog(id: $id) {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationLogs = /* GraphQL */ `
  query ListIntegrationLogs(
    $filter: ModelIntegrationLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        integrationId
        id
        log {
          level
          timestamp
          message
          srcDocId
          srcDocVersion
        }
        status
        tenantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationLogs = /* GraphQL */ `
  query SyncIntegrationLogs(
    $filter: ModelIntegrationLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        integrationId
        id
        log {
          level
          timestamp
          message
          srcDocId
          srcDocVersion
        }
        status
        tenantId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getIntegrationSettings = /* GraphQL */ `
  query GetIntegrationSettings($id: ID!) {
    getIntegrationSettings(id: $id) {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
        }
      }
      clientConfigurationFields {
        key
        value
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listIntegrationSettings = /* GraphQL */ `
  query ListIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        syncContentEvery
        name
        mapping {
          key
          fieldType
          dataType
          targetFieldName
          srcFieldname
          valueMappings {
            srcValue
            targetValue
          }
        }
        clientConfigurationFields {
          key
          value
        }
        integrationType
        enabled
        notificationEmail
        errorSyncEmail
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncIntegrationSettings = /* GraphQL */ `
  query SyncIntegrationSettings(
    $filter: ModelIntegrationSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncIntegrationSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        syncContentEvery
        name
        mapping {
          key
          fieldType
          dataType
          targetFieldName
          srcFieldname
          valueMappings {
            srcValue
            targetValue
          }
        }
        clientConfigurationFields {
          key
          value
        }
        integrationType
        enabled
        notificationEmail
        errorSyncEmail
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
        }
      }
      type
      fieldValues {
        fieldId
        valueOrId
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        lastSyncedAt
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        crmSyncStatus
        lastSyncedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        contentPresented {
          contentId
          groupId
          folderItemId
          title
          groupTitle
          status
          contentType
          events {
            pageNumber
            pageId
            timestamp
            end
          }
          openedAt
          closedAt
          presentedMeta {
            pageId
            presented
            followUp
            sentiment
            note
            title
          }
        }
        type
        fieldValues {
          fieldId
          valueOrId
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        attendees {
          id
          name
          status
          updatedAt
          email
          crmAccountId
          crmAddressId
          attendeeType
          crmRecord {
            crmCallId
            crmCustomValues {
              fieldId
              objectRecords {
                id
                externalId
                syncStatus
                status
                values {
                  fieldId
                  values
                }
              }
              values
            }
            crmSyncStatus
            lastSyncedAt
          }
          lastSyncedAt
        }
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMeetings = /* GraphQL */ `
  query SyncMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        contentPresented {
          contentId
          groupId
          folderItemId
          title
          groupTitle
          status
          contentType
          events {
            pageNumber
            pageId
            timestamp
            end
          }
          openedAt
          closedAt
          presentedMeta {
            pageId
            presented
            followUp
            sentiment
            note
            title
          }
        }
        type
        fieldValues {
          fieldId
          valueOrId
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        attendees {
          id
          name
          status
          updatedAt
          email
          crmAccountId
          crmAddressId
          attendeeType
          crmRecord {
            crmCallId
            crmCustomValues {
              fieldId
              objectRecords {
                id
                externalId
                syncStatus
                status
                values {
                  fieldId
                  values
                }
              }
              values
            }
            crmSyncStatus
            lastSyncedAt
          }
          lastSyncedAt
        }
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const attendemeetingsByCreatedByStartTimeesByHostIdStatus = /* GraphQL */ `
  query AttendemeetingsByCreatedByStartTimeesByHostIdStatus(
    $createdBy: ID!
    $startTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    attendemeetingsByCreatedByStartTimeesByHostIdStatus(
      createdBy: $createdBy
      startTime: $startTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        title
        startTime
        endTime
        contentPresented {
          contentId
          groupId
          folderItemId
          title
          groupTitle
          status
          contentType
          events {
            pageNumber
            pageId
            timestamp
            end
          }
          openedAt
          closedAt
          presentedMeta {
            pageId
            presented
            followUp
            sentiment
            note
            title
          }
        }
        type
        fieldValues {
          fieldId
          valueOrId
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        notes
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        attendees {
          id
          name
          status
          updatedAt
          email
          crmAccountId
          crmAddressId
          attendeeType
          crmRecord {
            crmCallId
            crmCustomValues {
              fieldId
              objectRecords {
                id
                externalId
                syncStatus
                status
                values {
                  fieldId
                  values
                }
              }
              values
            }
            crmSyncStatus
            lastSyncedAt
          }
          lastSyncedAt
        }
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          crmSyncStatus
          lastSyncedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getHub = /* GraphQL */ `
  query GetHub($id: ID!) {
    getHub(id: $id) {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listHubs = /* GraphQL */ `
  query ListHubs(
    $filter: ModelHubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        name
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        hubSections {
          id
          sectionHeader
          status
          type
          visible
          order
          textarea {
            content
          }
          sharedFiles {
            id
            contentId
            title
            contentType
            documentVersionSettings {
              associatedFiles {
                associatedFileId
                versionId
                notation {
                  id
                  type
                  description
                  status
                  pageId
                  coordinate {
                    x
                    y
                  }
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                }
              }
            }
            status
            createdAt
            updatedAt
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          toDos {
            id
            title
            status
            completedAt
            resolution
            order
            createdAt
            updatedAt
          }
          links {
            id
            url
            title
            status
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        meetingId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHubs = /* GraphQL */ `
  query SyncHubs(
    $filter: ModelHubFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHubs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        name
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        hubSections {
          id
          sectionHeader
          status
          type
          visible
          order
          textarea {
            content
          }
          sharedFiles {
            id
            contentId
            title
            contentType
            documentVersionSettings {
              associatedFiles {
                associatedFileId
                versionId
                notation {
                  id
                  type
                  description
                  status
                  pageId
                  coordinate {
                    x
                    y
                  }
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                }
              }
            }
            status
            createdAt
            updatedAt
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          toDos {
            id
            title
            status
            completedAt
            resolution
            order
            createdAt
            updatedAt
          }
          links {
            id
            url
            title
            status
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        meetingId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const hubsByOwnerByStatus = /* GraphQL */ `
  query HubsByOwnerByStatus(
    $createdBy: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hubsByOwnerByStatus(
      createdBy: $createdBy
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        status
        createdAt
        createdBy
        updatedAt
        updatedBy
        hubSections {
          id
          sectionHeader
          status
          type
          visible
          order
          textarea {
            content
          }
          sharedFiles {
            id
            contentId
            title
            contentType
            documentVersionSettings {
              associatedFiles {
                associatedFileId
                versionId
                notation {
                  id
                  type
                  description
                  status
                  pageId
                  coordinate {
                    x
                    y
                  }
                  createdAt
                  createdBy
                  updatedAt
                  updatedBy
                }
              }
            }
            status
            createdAt
            updatedAt
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          toDos {
            id
            title
            status
            completedAt
            resolution
            order
            createdAt
            updatedAt
          }
          links {
            id
            url
            title
            status
            order
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        meetingId
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomFormRecord = /* GraphQL */ `
  query GetCustomFormRecord($id: ID!) {
    getCustomFormRecord(id: $id) {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
          }
        }
        values
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCustomFormRecords = /* GraphQL */ `
  query ListCustomFormRecords(
    $filter: ModelCustomFormRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomFormRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        customFormId
        entity
        status
        crmFields {
          accountId
          externalId
          apiName
          recordTypeId
          syncStatus
          lastSyncedAt
        }
        name
        parentId
        parentModel
        values {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomFormRecords = /* GraphQL */ `
  query SyncCustomFormRecords(
    $filter: ModelCustomFormRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomFormRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tenantId
        customFormId
        entity
        status
        crmFields {
          accountId
          externalId
          apiName
          recordTypeId
          syncStatus
          lastSyncedAt
        }
        name
        parentId
        parentModel
        values {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customFormRecordByOwnerByStatus = /* GraphQL */ `
  query CustomFormRecordByOwnerByStatus(
    $createdBy: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomFormRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customFormRecordByOwnerByStatus(
      createdBy: $createdBy
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        customFormId
        entity
        status
        crmFields {
          accountId
          externalId
          apiName
          recordTypeId
          syncStatus
          lastSyncedAt
        }
        name
        parentId
        parentModel
        values {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const generateTokenForContentAccess = /* GraphQL */ `
  query GenerateTokenForContentAccess(
    $documentId: String!
    $documentVersionId: String!
    $authorizedPath: String!
    $durationSeconds: Int!
  ) {
    generateTokenForContentAccess(
      documentId: $documentId
      documentVersionId: $documentVersionId
      authorizedPath: $authorizedPath
      durationSeconds: $durationSeconds
    ) {
      token
      expiresAt
    }
  }
`;
export const exchangeContentShareTokenForAccessToken = /* GraphQL */ `
  query ExchangeContentShareTokenForAccessToken($token: ID!) {
    exchangeContentShareTokenForAccessToken(token: $token) {
      id
      contentId
      token
      key
      expiresAt
      fileName
      associatedFileId
      shareType
      fileExtension
      contentURL
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
      }
      title
      numPages
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      coverThumbnailPageNum
      sharedBy
      tenantId
    }
  }
`;
export const getHubAndContentDetailsByToken = /* GraphQL */ `
  query GetHubAndContentDetailsByToken($token: ID!) {
    getHubAndContentDetailsByToken(token: $token) {
      hubId
      tenantId
      title
      status
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      content {
        id
        contentId
        token
        key
        expiresAt
        fileName
        associatedFileId
        shareType
        fileExtension
        contentURL
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        title
        numPages
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        coverThumbnailPageNum
        sharedBy
        tenantId
      }
      tenantLogoUrl
      sharedBy
      featureFlags {
        flagId
        value
      }
    }
  }
`;
export const getHubAndContentDetailsById = /* GraphQL */ `
  query GetHubAndContentDetailsById($hubId: ID!) {
    getHubAndContentDetailsById(hubId: $hubId) {
      hubId
      tenantId
      title
      status
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
              }
            }
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      content {
        id
        contentId
        token
        key
        expiresAt
        fileName
        associatedFileId
        shareType
        fileExtension
        contentURL
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        title
        numPages
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        coverThumbnailPageNum
        sharedBy
        tenantId
      }
      tenantLogoUrl
      sharedBy
      featureFlags {
        flagId
        value
      }
    }
  }
`;
export const getProviderByUserEmail = /* GraphQL */ `
  query GetProviderByUserEmail($idpIdentifier: String!) {
    getProviderByUserEmail(idpIdentifier: $idpIdentifier)
  }
`;
export const getReportList = /* GraphQL */ `
  query GetReportList($folderName: String!, $isPublisherMode: Boolean) {
    getReportList(folderName: $folderName, isPublisherMode: $isPublisherMode) {
      dashboards {
        reports {
          dashboardId
          name
          group {
            name
            order
          }
        }
        dashboardUrl
        dashboardId
        name
        dashboardSheets {
          sheetId
          name
        }
      }
      isTeamLeader
    }
  }
`;
export const getEmbeddedUrl = /* GraphQL */ `
  query GetEmbeddedUrl(
    $dashboardId: String!
    $folderName: String!
    $isPublisherMode: Boolean
  ) {
    getEmbeddedUrl(
      dashboardId: $dashboardId
      folderName: $folderName
      isPublisherMode: $isPublisherMode
    )
  }
`;
export const getSharedFolders = /* GraphQL */ `
  query GetSharedFolders {
    getSharedFolders {
      folders {
        id
        tenantId
        name
        isPinned
        status
        items {
          id
          type
          itemId
          status
          addedAt
          updateAcknowledgedAt
          itemLastUpdatedAt
          visiblePages
          customTitle
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        sharePermissions {
          id
          isDeleted
          type
          targetType
          targetUsername
          targetCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
              }
            }
            values
          }
          targetLabels {
            key
            values
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          deletedBy
          deletedAt
        }
        shareStatus
        _version
        _deleted
        _lastChangedAt
      }
      permissions {
        id
        type
      }
    }
  }
`;
export const getDependenciesForSharedFolder = /* GraphQL */ `
  query GetDependenciesForSharedFolder($folderId: ID!, $parentFolderId: ID!) {
    getDependenciesForSharedFolder(
      folderId: $folderId
      parentFolderId: $parentFolderId
    ) {
      customDecks {
        id
        createdAt
        createdBy
        autoUpdateAcknowledgedAt
        updatedAt
        updatedBy
        tenantId
        groups {
          id
          pages {
            pageId
            pageNumber
            documentVersionId
          }
          visible
          srcId
          docAccessLevel
          name
          locked
        }
        title
        editMutex {
          userId
          timeStarted
          lastSeenAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      documents {
        id
        tenantId
        accessLevel
        status
        owner
        expiresAt
        integration {
          integrationId
          externalId
          firstSync
          lastSync
        }
        integrationType
        type
        editPermissions
        createdAt
        createdBy
        updatedAt
        updatedBy
        _version
        _deleted
        _lastChangedAt
      }
      documentVersions {
        id
        tenantId
        documentId
        versionNumber
        srcFilename
        contentURL
        conversionStatus
        status
        srcFile {
          bucket
          region
          key
          url
        }
        srcHash
        srcSize
        numPages
        pageSettings {
          pageId
          number
          isRequired
          linkedSlides
        }
        pageGroups {
          id
          pageIds
          name
          locked
          sourceID
          source
        }
        type
        releaseNotes
        changeType
        labelValues {
          key
          value
        }
        customValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
            }
          }
          values
        }
        title
        shortDescription
        longDescription
        owner
        expiresAt
        hasCopyright
        hasCustomThumbnail
        purpose
        canHideSlides
        distributable
        downloadable
        isInternalGenerated
        semVer {
          minor
          major
        }
        notificationScope
        selectedThumbnail
        publishedAt
        uploadedAt
        uploadedBy
        convertedArchiveKey
        convertedArchiveSize
        convertedFolderKey
        associatedFiles {
          id
          isDistributable
          isDefault
          type
          attachmentId
          status
          createdAt
          createdBy
        }
        editPermissions
        converterVersion
        createdAt
        createdBy
        updatedAt
        updatedBy
        integration {
          externalVersionId
          version
          timestamp
          srcFileHash
          srcDocumentHash
        }
        integrationType
        conversionWarningCode
        conversionErrorCode
        scheduledPublish
        _version
        _deleted
        _lastChangedAt
      }
      accessTokens {
        documentVersionId
        accessToken
        accessTokenExpire
      }
    }
  }
`;
export const getCustomDeckLambda = /* GraphQL */ `
  query GetCustomDeckLambda(
    $customDeckId: ID!
    $folderId: ID!
    $rootFolderId: ID!
  ) {
    getCustomDeckLambda(
      customDeckId: $customDeckId
      folderId: $folderId
      rootFolderId: $rootFolderId
    ) {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
        }
        visible
        srcId
        docAccessLevel
        name
        locked
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getAvailableIntegrationsByCognitoUser = /* GraphQL */ `
  query GetAvailableIntegrationsByCognitoUser {
    getAvailableIntegrationsByCognitoUser {
      id
      tenantId
      name
      integrationType
      enabled
    }
  }
`;
export const getZendeskJWT = /* GraphQL */ `
  query GetZendeskJWT($feature: ZENDESK_FEATURE!) {
    getZendeskJWT(feature: $feature) {
      token
      expiresAt
    }
  }
`;
export const getRefreshedContentAccessToken = /* GraphQL */ `
  query GetRefreshedContentAccessToken(
    $expiredToken: String!
    $durationSeconds: Int!
  ) {
    getRefreshedContentAccessToken(
      expiredToken: $expiredToken
      durationSeconds: $durationSeconds
    ) {
      token
      expiresAt
    }
  }
`;
export const getCRMAuthUrl = /* GraphQL */ `
  query GetCRMAuthUrl {
    getCRMAuthUrl
  }
`;
export const getCRMAuthInformation = /* GraphQL */ `
  query GetCRMAuthInformation($code: String!) {
    getCRMAuthInformation(code: $code) {
      accessToken
      instanceUrl
      refreshToken
      userInfo {
        id
        organizationId
        displayName
        thumbnail
      }
    }
  }
`;
export const logOutCRM = /* GraphQL */ `
  query LogOutCRM($accessToken: String) {
    logOutCRM(accessToken: $accessToken)
  }
`;
export const refreshTokenCRM = /* GraphQL */ `
  query RefreshTokenCRM($accessToken: String, $refreshToken: String) {
    refreshTokenCRM(accessToken: $accessToken, refreshToken: $refreshToken) {
      accessToken
      issuedAt
      signature
      id
    }
  }
`;
export const getPoolUserSubIdByEmail = /* GraphQL */ `
  query GetPoolUserSubIdByEmail($email: String!) {
    getPoolUserSubIdByEmail(email: $email)
  }
`;
export const fullTextSearch = /* GraphQL */ `
  query FullTextSearch(
    $queryText: String!
    $filter: FullTextSearchFilterInput
    $configName: String
  ) {
    fullTextSearch(
      queryText: $queryText
      filter: $filter
      configName: $configName
    ) {
      items {
        documentId
        title {
          text
          highlights {
            beginOffset
            endOffset
            topAnswer
            type
          }
        }
        documentExcerpt {
          text
          highlights {
            beginOffset
            endOffset
            topAnswer
            type
          }
        }
        queryId
        resultId
        pageNumber
      }
      searchServerTime
      searchClientTime
    }
  }
`;
