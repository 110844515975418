import { Auth } from '@aws-amplify/auth';
import { API, graphqlOperation } from '@aws-amplify/api';
import { generateTokenForContentAccess } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import { throttle } from 'lodash';

type allowedHeaders = 'Alucio-Action' | 'Alucio-Content-Bucket' | 'Alucio-Authorization'
type aditionalHeaders = { [key in allowedHeaders]?: string }

function arrayBufferToBase64(buffer:ArrayBuffer):string {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => { binary += String.fromCharCode(b) });

  return window.btoa(binary);
}

async function getImageFromCloudfront(key:string):Promise<string> {
  if (key) {
    const imgArrayData = await getContentFromCloudfront(key);
    const base64Flag = 'data:image/png;base64,';
    const imageStr = base64Flag + arrayBufferToBase64(imgArrayData);
    return imageStr;
  } else {
    return '';
  }
}

/**
 * Utility function which creates a request for content from cloudfront, then returns an object url generated from the blob contained in the response
 * @param key path for content stored in the S3 bucket. E.g. thumbs are in the format {convertedFolderKey}{pageId}_thumb.png
 */
async function getImageObjectURLFromCloudfront(key:string):Promise<string> {
  const response = fetchContentFromCloudfront(key)
    .then( (response) => response.blob())
    .then((blob) => {
      const objectURL = URL.createObjectURL(blob)
      return objectURL
    })
  return response
}

/**
 * Utility function which makes a generic fetch request to cloudfront and returns the response
 * @param key path for content stored in the S3 bucket. E.g. thumbs are in the format {convertedFolderKey}{pageId}_thumb.png
 */
async function fetchContentFromCloudfront(key:string):Promise<Response> {
  const authHeaders = await getAuthHeaders()
  const url = `/content/${key}`
  const response = fetch(url, {
    method: 'GET',
    headers: authHeaders,
  })
  return response
}

async function getContentFromCloudfront(
  key:string,
  aditionalHeaders?: aditionalHeaders,
):Promise<ArrayBuffer> {
  const authHeaders = await getAuthHeaders()
  const url = `/content/${key}`;
  const imgResponse = await fetch(url, {
    method: 'GET',
    headers: { ...authHeaders, ...aditionalHeaders },
  });
  return await imgResponse.arrayBuffer();
}

async function downloadRecordingFromCloudfront(
  tenantId: string| undefined,
  sessionId: string,
  fileName: string,
): Promise<void> {
  const bucket = AWSConfig.aws_user_files_s3_bucket;

  const tokenResp =  await API.graphql(
    graphqlOperation(generateTokenForContentAccess, {
      documentId: 'recordings',
      documentVersionId: sessionId,
      authorizedPath: `${tenantId}/recordings/${sessionId}/1.mp4`,
      durationSeconds: 15,
    })) as any;

  const JWT = encodeURIComponent(tokenResp.data.generateTokenForContentAccess.token)

  const url = `/content/${tenantId}/recordings/${sessionId}/1.mp4?token=${JWT}&fileName=${fileName}&bucket=${bucket}`;

  var recording = document.createElement('a');
  recording.href = url;
  recording.target = '_blank'
  recording.click();
}

async function fetchJsonFromCloudfront(key:string, disabledCache?: boolean):Promise<Response> {
  const authHeaders = await getAuthHeaders()
  const url = `/content/${key}`
  if (disabledCache) {
    // eslint-disable-next-line dot-notation
    authHeaders['Cache-Control'] = 'no-cache'
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'Accept': 'application/json',
    },
  })
  return response
}

/**
 * Utility function which to retry a promise a number of times before throwing an error
 */
export const retryPromise = async (
  promise: Promise<any>,
  retries = 3,
  waitBetweenRetries = 5000,
  _debugId?: string,
) => {
  let error;
  for (let i = 0; i < retries; i++) {
    try {
      return await promise;
    } catch (e) {
      error = e;
      await new Promise((resolve) => setTimeout(resolve, waitBetweenRetries));
    }
  }
  throw error;
};

export const fetchPagesJson = async (s3Keys, disableCache) => {
  // FETCH THE CONTENT OF EACH PAGE'S DOC
  return await Promise.all(Object.keys(s3Keys).map(async (key) => {
    const response = await fetchJsonFromCloudfront(s3Keys[key], disableCache);
    const formattedResponse = await response.json();
    return {
      documentVersionId: key,
      content: formattedResponse,
    };
  }));
};

export const fetchPagesJsonWithThrottle = throttle(
  async (s3Keys, disableCache) => await fetchPagesJson(s3Keys, disableCache),
  500,
);

// TODO: investigate a way to get the offline flag from app settings without having a parameter/property driling
const getAuthHeaders = async () => (navigator.onLine ? {
  'Alucio-Authorization': (await Auth.currentSession())
    .getIdToken()
    .getJwtToken(),
  'Alucio-Content-Bucket': AWSConfig.aws_user_files_s3_bucket,
} : {
  'Alucio-Authorization': '',
  'Alucio-Content-Bucket': AWSConfig.aws_user_files_s3_bucket,
})

export {
  getImageFromCloudfront,
  getImageObjectURLFromCloudfront,
  fetchContentFromCloudfront,
  getContentFromCloudfront,
  downloadRecordingFromCloudfront,
  getAuthHeaders,
  fetchJsonFromCloudfront,
}
