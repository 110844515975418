import { useState, useEffect } from 'react';
import { Presentable } from 'src/types/types';
import { fetchPagesJson, fetchPagesJsonWithThrottle } from 'src/utils/loadCloudfrontAsset/common';
import { detectArchivedFileKeyPath } from 'src/components/SlideSelector/useThumbnailSelector';

interface Recommendations {
  pageId: string
  score: number
}

export interface ContentPageData {
  content?: string;
  presentationPageNumber: number;
  speakerNotes?: string;
  s3Key: string;
  title?: string;
  recommendations: Recommendations[];
  mapping: string | null;
}

// External cache to store fetched data
const cache: { [key: string]: ContentPageData[] } = {};
/**
 * Function for fetching the JSON file contents of a given presentable
 * @param presentable: Presentable - Contains the pages to search over
 * @param searchText: string - Text to find in presentable's pages
 * @returns { matches: MatchedPage[], totalMatchCount: number }
 */
export const useContentPageData = (presentable?: Presentable, disableCache?: boolean, disableThrottle?: boolean) => {
  const [contentPageData, setContentPageData] = useState<ContentPageData[] | undefined>(undefined);

  // Fetches
  useEffect(() => {
    if (!presentable) return;

    // Check if data is already cached
    const cacheKey = presentable.orm.model.id;
    if (cacheKey in cache && !disableCache) {
      setContentPageData(cache[cacheKey]);
      return;
    }

    // Fetch the data and update the cache
    const getDocumentsTextsAndFormat = async () => {
      const s3Keys = presentable.presentablePages.reduce<{ [key: string]: string }>((acc, page) => {
        // since we are storing the titles and speaker notes in the json file, we need to make sure we are
        // getting the correct version of the file. If the file has been updated in the last 10 minutes,
        // we will use the current version of the file. Otherwise, we will use the version of the file
        // that was published at the time of the presentation. This is to ensure that the titles and
        // speaker notes are correct for the presentation, and avoid any caching issues (disk and cloudfront).
        if (!page.documentVersionORM.model.convertedFolderKey) return acc
        const publishedAtDate =  page.documentVersionORM.model.publishedAt
          ? new Date( page.documentVersionORM.model.publishedAt) : null
        const timeDiff = publishedAtDate ? (new Date().getTime() - publishedAtDate.getTime()) / 60000 : 0
        const param = timeDiff < 10 || !publishedAtDate ? `?${new Date().getTime()}`
          : `?${publishedAtDate.getTime()}`
        acc[page.documentVersionORM.model.id] =
        `${page.documentVersionORM.model.convertedFolderKey}json/Pages.json${param}`;
        return acc;
      }, {});

      const responses = disableThrottle
        ? await fetchPagesJson(s3Keys, disableCache)
        : await fetchPagesJsonWithThrottle(s3Keys, disableCache)

      if (!responses) return []

      const pages: ContentPageData[] = [];
      // ASSIGNS THE CONTENT TO EACH PAGE
      presentable.presentablePages.forEach((page) => {
        const formattedPage: ContentPageData = {
          presentationPageNumber: page.presentationPageNumber,
          s3Key: detectArchivedFileKeyPath(page.documentVersionORM.model, page.page, 'sm') || '',
          recommendations: [],
          mapping: null,
        };

        // FINDS THE CONTENT FOR THE PAGE AND ASSIGNS IT
        responses.forEach((response) => {
          if (response.documentVersionId === page.documentVersionORM.model.id) {
            // FINDS THE PAGE
            response.content.pages.forEach((responsePage) => {
              if (responsePage?.number === page.page.number) {
                formattedPage.speakerNotes = responsePage.overrideSpeakerNotes ?? responsePage.speakerNotes;
                formattedPage.content = responsePage.content;
                formattedPage.title = responsePage.overrideTitle ?? responsePage.title;
                formattedPage.recommendations = responsePage.recommendations ?? [];
                formattedPage.mapping = responsePage.mapping ?? null;
              }
            });
          }
        });
        pages.push(formattedPage);
      });

      // Update cache and state
      cache[cacheKey] = pages;
      setContentPageData(pages);
    };

    setContentPageData(undefined);
    getDocumentsTextsAndFormat();
  }, [presentable?.orm.model.id, presentable?.numberOfPages, disableCache]);

  if (presentable && cache[presentable.orm.model.id] && !disableCache) {
    return {
      contentPageData: cache[presentable.orm.model.id],
    };
  }

  return {
    contentPageData : contentPageData || [],
  }
}

export const getSlideContentPageDataTitle = (idx:number, contentPageData? : ContentPageData[],
  showDefaultTile : boolean = false ) : string => {
  const defaultTitle = showDefaultTile ? `Slide ${idx + 1}` : '';
  if (!contentPageData || !contentPageData[idx]) return defaultTitle
  return contentPageData[idx].title || defaultTitle
}

export const hasSpeakerNotes = (idx:number, contentPageData? : ContentPageData[]) : boolean => {
  if (!contentPageData || !contentPageData[idx]) return false
  return !!contentPageData[idx].speakerNotes
}

export default useContentPageData;
