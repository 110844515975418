import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  StyleSheet,
  useWindowDimensions,
  VirtualizedListWithoutRenderItemProps,
} from 'react-native';
import { RecyclerListView, DataProvider, LayoutProvider, RecyclerListViewProps } from 'recyclerlistview';
import equal from 'fast-deep-equal';
import { DNABox, DNAButton, DNAText, Icon, Iffy } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useSelector, { composite } from 'src/hooks/useSelector';
import * as findReplacementSelector from 'src/state/machines/findReplacement/FindReplacement.selectors';
import SlideRollThumbnail from 'src/components/Thumbnail/SlideRollThumbnail';
import { useFindReplacementState, ReplaceGroupDraft } from './FindReplacementProvider';
import { ScrollEvent } from 'recyclerlistview/dist/reactnative/core/scrollcomponent/BaseScrollView';
import { RecyclerListViewState } from 'recyclerlistview/dist/reactnative/core/RecyclerListView';

const { useNeedReviewGroupSlideThumbnail } = findReplacementSelector

const S = StyleSheet.create({
  slideRollContainer: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  thumbnailBorder: {
    borderColor: 'transparent',
    borderWidth: 5,
    marginHorizontal: 6,
  },
  neededReviewActiveWrapper: {
    borderColor: colors['color-warning-500'],
  },
  neededReviewWrapper: {
    borderColor: colors['color-warning-100'],
  },
  doneReviewWrapper: {
    borderColor: colors['color-success-500'],
  },
  slideTitleContainer: {
    width: 120,
    marginTop: 4,
    marginHorizontal: 6,
  },
  scrollView: {
    marginHorizontal: 16,
  },
  rect1: {
    backgroundColor: colors['color-warning-80'],
    borderRadius: 3,
    left: 3,
    bottom: 3,
  },
  rect2: {
    backgroundColor: colors['color-warning-500'],
    borderRadius: 3,
    left: 6,
    bottom: 3,
  },
  checkIcon: {
    color: colors['color-success-500'],
    height: 17,
    marginRight: 2,
    width: 17,
  },
});

const SlideFooterText: React.FC<{
  slideNum?: number,
  doneReview: boolean,
  pageTitle: string,
}> = ({
  slideNum,
  doneReview,
  pageTitle,
}) => {
  return (
    <DNABox fill style={S.slideTitleContainer}>
      <DNAText>{(slideNum ? `${slideNum}. ` : '')}</DNAText>
      <Iffy is={doneReview}>
        <Icon style={S.checkIcon} name="check-circle" />
      </Iffy>
      <DNAText numberOfLines={1}>{pageTitle}</DNAText>
    </DNABox>
  )
}

const SlideThumb: React.FC<{groupId: string}> = ({
  groupId,
}) => {
  const { service, setActiveGroup, slideRollRef } = useFindReplacementState();

  const parentGroup = useNeedReviewGroupSlideThumbnail(service, groupId);
  const [memoizedParentGroup, setMemoizedParentGroup] = useState(parentGroup);

  useEffect(() => {
    if (!equal(memoizedParentGroup, parentGroup)) setMemoizedParentGroup(parentGroup);
  }, [parentGroup])

  useEffect(() => {
    if (memoizedParentGroup?.isActive) {
      slideRollRef.current?.scrollTo({
        x: memoizedParentGroup.idx > 0 ? (132 * memoizedParentGroup.idx) : 0,
        animated: true,
      })
    }
  }, [memoizedParentGroup])

  const slideFooter = useMemo(
    () => {
      if (!memoizedParentGroup) return undefined
      return (
        <SlideFooterText
          slideNum={memoizedParentGroup.slideNum}
          doneReview={memoizedParentGroup.doneReview}
          pageTitle={memoizedParentGroup.pageTitle}
        />
      )
    },
    [memoizedParentGroup],
  )

  if (!memoizedParentGroup) return null

  return (
    <DNABox>
      <SlideRollThumbnail
        isGroup={memoizedParentGroup.isGroup}
        isUnavailableDocument={memoizedParentGroup.isUnavailableDocument}
        unavailableText={memoizedParentGroup.unavailableLabel}
        s3URL={memoizedParentGroup.s3URL}
        status={memoizedParentGroup.doneReview ? 'success' : 'warning'}
        isSelected={memoizedParentGroup.isActive}
        onPress={() => setActiveGroup(groupId)}
        slideFooter={slideFooter}
        lazyLoad={false}
      />
    </DNABox>
  )
};

const SlideRoll: React.FC = () => {
  const { service, slideRollRef } = useFindReplacementState();
  const dimensions = useWindowDimensions();
  const recyclerListRef = useRef<RecyclerListView<RecyclerListViewProps, RecyclerListViewState> | null>(null);
  const [scrollOffset, setScrollOffset] = useState<number>(0)
  const [allowScroll, setAllowScroll] = useState<boolean>(true)
  const [contentWidth, setContentWidth] = useState<number>(0)

  const cond = useSelector(
    service,
    (state) => composite(
      state,
      findReplacementSelector.needReviewGroups,
      findReplacementSelector.activeGroupId,
    ),
  );

  const renderThumbnail = useCallback(
    (_type: string | number, group: ReplaceGroupDraft, _index: number) => {
      return (
        <SlideThumb
          key={group.groupId}
          groupId={group.groupId}
        />
      )
    },
    [],
  )

  const dataProvider = useMemo(() => {
    return new DataProvider((r1, r2) => r1.groupId !== r2.groupId)
      .cloneWithRows(cond.needReviewGroups);
  }, [cond.needReviewGroups]);

  const layoutProvider = useMemo(() => {
    return new LayoutProvider(
      () => 0,
      (_type, dim) => {
        dim.width = 132;
        dim.height = 120;
      },
    );
  }, []);

  const handleScroll = (_rawEvent: ScrollEvent, offsetX: number, _offsetY: number) => {
    setScrollOffset(offsetX);
  };

  const onScroll = useCallback((direction: 'left' | 'right') => {
    (slideRollRef.current as any)?.measure(
      (fx, fy, w) => {
        const numOfPreviousSlide = Math.floor(scrollOffset / 132)
        const numberOfVisibleSlide = Math.floor(w / 132)
        const indexOfSlideToScrollTo = direction === 'left'
          ? numOfPreviousSlide - numberOfVisibleSlide + 1
          : numOfPreviousSlide + numberOfVisibleSlide
        slideRollRef.current?.scrollTo({
          x: indexOfSlideToScrollTo * 132,
          animated: true,
        })
      },
    )
  }, [scrollOffset]);

  const onScrollToIndexFailed = useCallback<
    NonNullable<
      VirtualizedListWithoutRenderItemProps<ReplaceGroupDraft>['onScrollToIndexFailed']
    >
  >(
    (info) => {
      setTimeout(
        () => {
          recyclerListRef.current?.scrollToIndex(info.index, true);
        },
        500,
      );
    },
    [],
  )

  const setScrollViewRef = useCallback((node: any) => {
    const scrollableNode = node?.getScrollableNode()
    if (!node) return
    slideRollRef.current = scrollableNode

    // add the event on scroll to index failed
    scrollableNode.addEventListener('scrollToIndexFailed', onScrollToIndexFailed)
  }, [onScrollToIndexFailed]);

  useEffect(() => {
    if (contentWidth) {
      if ((dimensions.width - 64) > contentWidth) setAllowScroll(false)
      else setAllowScroll(true)
    }
  }, [dimensions, contentWidth])

  const onContentSizeChange = useCallback(contentWidth => {
    setContentWidth(contentWidth)
    if ((dimensions.width - 64) > contentWidth) setAllowScroll(false)
    else setAllowScroll(true)
  }, [setContentWidth, setAllowScroll])

  if (!cond.needReviewGroups.length) return null
  return (
    <DNABox alignX="center" alignY="center" style={S.slideRollContainer}>
      <Iffy is={allowScroll}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="md"
          padding="sm"
          iconLeft="chevron-left"
          onPress={() => onScroll('left')}
        />
      </Iffy>
      <DNABox fill alignX="center" alignY="center" style={S.scrollView}>
        <RecyclerListView<RecyclerListViewProps, RecyclerListViewState>
          key="find-and-replace-slide-roll"
          ref={recyclerListRef}
          layoutProvider={layoutProvider}
          dataProvider={dataProvider}
          rowRenderer={renderThumbnail}
          isHorizontal={true}
          onScroll={handleScroll}
          scrollViewProps={{
            horizontal: true,
            ref: setScrollViewRef,
            scrollEventThrottle: 16,
            onContentSizeChange: onContentSizeChange,
          }}
          renderAheadOffset={600}
          onEndReachedThreshold={0.5}
          style={{ height: 120 }}
        />
      </DNABox>
      <Iffy is={allowScroll}>
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="md"
          padding="sm"
          iconLeft="chevron-right"
          onPress={() => onScroll('right')}
        />
      </Iffy>
    </DNABox>
  );
};

export default SlideRoll;
