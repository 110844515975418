import React, { ReactElement, useMemo, useState } from 'react'
import { Pressable, StyleProp, StyleSheet, TouchableOpacityProps, ViewStyle } from 'react-native'
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNAChip,
  DNAIcon,
  DNAText,
  GridList,
  Iffy,
  luxColors,
  util,
} from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  DocumentStatus,
  Tenant,
  FieldStatus,
  FileType,
  FieldDataType,
} from '@alucio/aws-beacon-amplify/src/models';
import { Variant, DocumentORM, DocumentVersionORM } from 'src/types/types';
import { SortDir, SortEntry, SortFieldType, SortMap } from '../hooks/useSort';
import DNADocumentStatusDot from 'src/components/DNA/Document/DNADocumentStatusDot'
import { ActionCallbacks, useDNADocumentActions } from '../Document/DNADocument.actions';
import DNADocumentContextMenu from '../Document/DNADocumentContextMenu/DNADocumentContextMenu';
import { DNACard } from '@alucio/lux-ui/src/components/layout/DNACard/DNACard';
import DNADocumentThumbnail from '../Document/DNADocumentThumbnail';
import DNADocumentChip from '../Document/DNADocumentChip';
import { format } from 'date-fns-tz';
import kebabCase from 'lodash/kebabCase'
import cloneDeep from 'lodash/cloneDeep'
import useCurrentPage from '../hooks/useCurrentPage';
import CustomFieldBadgeList from 'src/components/CustomFields/CustomFieldBadgeList';
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

export interface ListFieldConfig {
  id?: string,
  fieldName: string,
  altFieldName?: string,
  fieldLabel: string,
  fieldType: ('LABEL' | 'MODEL' | 'ACTION_MENU' | 'DOC_MODEL'),
  dataType?: ('string' | 'date' | 'boolean')
  width: number,
  defaultSort?: SortDir,
  showByDefault?: boolean,
  isBadge?: boolean,
  fieldResolver?: (docVer: DocumentVersionORM) => any
}

export interface DNADocumentListRowProps {
  documentORM: DocumentORM,
  variant: Variant,
  isDesktop: boolean,
  isOnline: boolean,
  onPress?: (doc: DocumentORM) => void,
  onThumbnailPress?: (doc: DocumentORM) => void,
  actionCallbacks?: ActionCallbacks,
  fields: ListFieldConfig[],
  cellStyle?: StyleProp<ViewStyle>
  rowStyle?: StyleProp<ViewStyle>
  selectEnabled?: boolean,
  checked?: boolean,
  onCheck?: () => void,
}

const VALUE_MAX_TEXT_LINES = 5

export const SYSTEM_FIELD_CONFIGS: Record<string, ListFieldConfig> = {
  'ACTION_MENU': {
    fieldName: 'action',
    fieldLabel: '',
    fieldType: 'ACTION_MENU',
    width: 32,
  },
  'DOC_CREATED_AT': {
    dataType: 'date',
    fieldName: 'createdAt',
    fieldLabel: 'Created At',
    fieldType: 'DOC_MODEL',
    width: 100,
  },
  'DOC_CREATED_BY': {
    dataType: 'string',
    fieldName: 'createdBy',
    fieldLabel: 'Created By',
    fieldType: 'DOC_MODEL',
    width: 240,
  },
  'PUBLISHER_DOC_UPDATED_AT': {
    dataType: 'date',
    fieldName: 'publishedAt',
    altFieldName: 'createdAt',
    fieldResolver: (docVersion: DocumentVersionORM) => {
      const latestPublished = docVersion.relations.documentORM.relations.version.latestPublishedDocumentVersionORM
      // For Publisher list date column we have to pick the date based on certain conditions:
      // - If the doc is sealed use the publishedAt value
      // - If the latest doc version is a draft in progress use the createdAt value
      // - If the latest doc version is published use the publishedAt value
      const value = docVersion.meta.sealedStatus
        ? latestPublished?.model.publishedAt
        : docVersion.model.status !== 'PUBLISHED'
          ? docVersion.model.createdAt
          : docVersion.model.publishedAt

      return value
    },
    fieldLabel: 'Date',
    fieldType: 'MODEL',
    width: 100,
  },
  'BOOKMARK_DOC_UPDATED_AT': {
    dataType: 'date',
    fieldName: 'publishedAt',
    fieldResolver: (docVersion: DocumentVersionORM) => {
      return docVersion.relations.documentORM.meta.bookmark.createdAt
    },
    fieldLabel: 'Date',
    fieldType: 'MODEL',
    width: 100,
  },
  'DOC_UPDATED_AT': {
    dataType: 'date',
    fieldName: 'publishedAt',
    fieldLabel: 'Date',
    fieldType: 'MODEL',
    width: 100,
  },
  'DOC_UPDATED_BY': {
    dataType: 'string',
    fieldName: 'updatedBy',
    fieldLabel: 'Last Updated By',
    fieldType: 'MODEL',
    width: 240,
  },
  'FILE_TYPE': {
    dataType: 'string',
    fieldName: 'type',
    fieldLabel: 'Type',
    fieldType: 'MODEL',
    width: 50,
    fieldResolver: (docVersion: DocumentVersionORM) => {
      return docVersion.model.type === 'MP4' ? 'VIDEO' : docVersion.model.type
    },
  },
  'ID': {
    dataType: 'string',
    fieldName: 'id',
    fieldLabel: 'ID',
    fieldType: 'DOC_MODEL',
    width: 80,
  },
  'LABEL_STATUS_DOT': {
    dataType: 'string',
    fieldName: 'labelStatusDot',
    fieldLabel: '',
    fieldType: 'MODEL',
    width: 8,
  },
  'STATUS': {
    dataType: 'string',
    fieldName: 'status',
    fieldLabel: 'Status',
    fieldType: 'DOC_MODEL',
    width: 125,
  },
  'THUMBNAIL': {
    fieldName: 'thumbnail',
    fieldLabel: '',
    fieldType: 'MODEL',
    width: 89,
  },
  'TITLE': {
    dataType: 'string',
    fieldName: 'title',
    fieldLabel: 'Name',
    fieldType: 'MODEL',
    width: 384,
  },
  'CONTENT_SOURCE': {
    dataType: 'string',
    fieldName: 'source',
    fieldLabel: 'Content Source',
    fieldType: 'MODEL',
    width: 120,
    fieldResolver: (docVersion: DocumentVersionORM) => docVersion.meta.integration.source,
  },

}

const S = StyleSheet.create({
  documentThumbnailBorder: {
    borderWidth: 1,
    borderColor: luxColors.border.primary,
  },
  hoveredRow: {
    backgroundColor: luxColors.mainContainer.primary,
  },
  unPublishedVersionStatus: {
    borderWidth: 2,
    borderColor: luxColors.warning.quaternary,
  },

});

export const getSortConfigForFields = (fieldConfigs: ListFieldConfig[], ignoreDefaultSort?: boolean): SortMap => {
  const initSort: SortMap = {}
  fieldConfigs.filter(field => field).forEach((field) => {
    if (field.fieldType === 'LABEL' || field.fieldType === 'MODEL' || field.fieldType === 'DOC_MODEL') {
      const sortConfig: SortEntry = {
        id: field.id ?? field.fieldName,
        key: field.fieldName,
        fieldResolver: field.fieldResolver,
        fieldType: field.fieldType === 'LABEL' ? SortFieldType.label
          : field.fieldType === 'MODEL' ? SortFieldType.ver_model : SortFieldType.doc_model,
        label: field.fieldLabel,
        dir: !ignoreDefaultSort && field.defaultSort ? field.defaultSort : SortDir.none,
        // when we sort a custom field that represents a date, we want to sort by value
        // the value that the user see in the screen is the formatted date is not saved on the iso format
        orderByValue: field.dataType === 'date',
      }
      if (sortConfig.dir !== SortDir.none) { sortConfig.icon = SortDir.desc ? 'menu-down' : 'menu-up' }

      initSort[field.fieldName] = sortConfig
    }
  })
  return initSort;
}

// TODO: This is a temporary solution to convert the CustomFieldDefinition['fieldType'] to ListFieldConfig['dataType']
// We should remove this once we have a better solution
// Probably use the saame enumerator that we save on the custom values
const toListConfigDataType = (dataType: CustomFieldDefinition['fieldType']): ListFieldConfig['dataType'] => {
  switch (dataType) {
    case FieldDataType.CATEGORICAL:
      return 'string'
    case FieldDataType.MULTICATEGORICAL:
      return 'string'
    case FieldDataType.DATE:
      return 'date'
    case FieldDataType.USER_LIST:
      return 'string'
    default:
      return 'string'
  }
}

const getFieldConfigForCustomField = (field: CustomFieldDefinition): ListFieldConfig => {
  return {
    id: field.id,
    fieldName: field.fieldName,
    fieldType: 'LABEL',
    fieldLabel: field.fieldLabel,
    width: 240,
    isBadge: field.settings?.isBadge,
    dataType: toListConfigDataType(field.fieldType),
  }
}

export const getFieldConfigsForTenant = (
  tenant: Tenant | undefined,
  variant: Variant,
  isDesktop: boolean,
): ListFieldConfig[] => {
  let fieldConfig: ListFieldConfig[] = [];

  // We lock the first couple of columns to ensure consistent UI
  const systemFields = cloneDeep(SYSTEM_FIELD_CONFIGS) as Record<string, ListFieldConfig>
  if (!isDesktop) {
    systemFields.ACTION_MENU.width = 60;
    systemFields.THUMBNAIL.width = 90;
  }
  if (variant === Variant.MSL || variant === Variant.bookmark) {
    const actionMenu = {
      ...systemFields.ACTION_MENU,
      width: !isDesktop ? 140 : 100,
    };
    fieldConfig.push(actionMenu);
    fieldConfig.push(systemFields.THUMBNAIL);
    fieldConfig.push(systemFields.TITLE);
  } else {
    fieldConfig.push(systemFields.THUMBNAIL);
    fieldConfig.push(systemFields.ACTION_MENU);
    fieldConfig.push(systemFields.LABEL_STATUS_DOT);
    fieldConfig.push(systemFields.TITLE);
    fieldConfig.push(systemFields.STATUS);
  }

  const customConfig =
    variant === Variant.MSL || variant === Variant.bookmark
      ? tenant?.config?.mslListConfig
      : tenant?.config?.publisherListConfig;

  if (customConfig) {
    const customFields = customConfig.fields.map((field) => {
      let config: ListFieldConfig;
      if (systemFields[field.field]) {
        if (variant === Variant.publisher && field.field === 'DOC_UPDATED_AT') {
          config = systemFields.PUBLISHER_DOC_UPDATED_AT;
        }
        else if (variant === Variant.bookmark && field.field === 'DOC_UPDATED_AT') {
          config = systemFields.BOOKMARK_DOC_UPDATED_AT;
        }
        else {
          config = systemFields[field.field];
        }
      }
      else {
        const tenantFieldConfig = tenant?.config?.customFields?.find(
          (label) => label.id === field.field || label.fieldName === field.field,
        );
        if (!tenantFieldConfig) {
          throw new Error(`Invalid tenant list configuration field \
        ${field.field} not found in tenant's field configuration`);
        } else if (tenantFieldConfig.status === FieldStatus.DISABLED) {
          throw new Error(`Invalid tenant list configuration field \
        ${field.field} (${tenantFieldConfig.fieldLabel}) is disabled`);
        }
        config = getFieldConfigForCustomField(tenantFieldConfig);
      }
      config.width = field.width ? field.width : config.width;
      if (config.width < 150) {
        config.width = 150;
      }
      return config;
    });
    fieldConfig = [...fieldConfig, ...customFields];
    if (customConfig.sort) {
      const sortFieldName = systemFields[customConfig.sort.field]
        ? systemFields[customConfig.sort.field].fieldName
        : customConfig.sort.field;
      const sortField = fieldConfig.find(
        (field) => field.fieldName === sortFieldName || field.id === sortFieldName,
      );
      if (!sortField) {
        throw new Error(`Invalid tenant list configuration sort field \
        ${customConfig.sort.field} not found in available list fields`);
      }
      sortField.defaultSort = customConfig.sort.isAsc
        ? SortDir.asc
        : SortDir.desc;
    }
  }
  else {
    const defaultTenantFields = (
      tenant?.config?.customFields?.filter((field) => (
        !!field.showByDefault &&
        !field.settings?.isBadge && field.usage.includes(CustomFieldUsage.DOCUMENT) &&
        field.status === FieldStatus.ENABLED
      )) ?? []).map(getFieldConfigForCustomField);
    const badgeField = tenant?.config?.customFields?.find((field) => field.settings?.isBadge &&
      field.usage.includes(CustomFieldUsage.DOCUMENT) && field.status === FieldStatus.ENABLED);
    if (badgeField) {
      defaultTenantFields.unshift(getFieldConfigForCustomField(badgeField));
    }

    if (variant === Variant.MSL) {
      fieldConfig = [...fieldConfig, ...defaultTenantFields];
      fieldConfig.push({
        ...systemFields.DOC_UPDATED_AT,
        defaultSort: SortDir.desc,
      });
    }
    else if (variant === Variant.bookmark) {
      fieldConfig = [...fieldConfig, ...defaultTenantFields];
      fieldConfig.push({
        ...systemFields.BOOKMARK_DOC_UPDATED_AT,
        defaultSort: SortDir.desc,
      });
    }
    else {
      fieldConfig = [...fieldConfig, ...defaultTenantFields];
      fieldConfig.push({
        ...systemFields.PUBLISHER_DOC_UPDATED_AT,
        defaultSort: SortDir.desc,
      });
    }
  }
  return fieldConfig;
};

enum FieldHeaderVariants {
  DEFAULT,
  ADMIN_SETTINGS,
  HUBS,
}
type FieldHeaderVariant = keyof typeof FieldHeaderVariants;

interface FieldHeaderProps {
  field: ListFieldConfig,
  toggleSort: (string) => void
  sortIcon: ('menu-down' | 'menu-up' | undefined),
  variant?: FieldHeaderVariant
}

interface FieldHeaderVariantConfig {
  buttonStatus: DNAButtonProps['status'],
  buttonContent: React.ReactNode
}

type FieldHeaderVariantConfigs = Record<FieldHeaderVariant, FieldHeaderVariantConfig>

export const ScheduleDocumentIcon: React.FC<{
  scheduledPublish?: string,
  mode: 'ICON' | 'TEXT'
}> = (props) => {
  const { scheduledPublish, mode } = props
  const formattedDate = scheduledPublish ? format(new Date(scheduledPublish), "MMMM d, yyyy 'at' h:mm aa z") : '';

  if (mode === 'ICON') {
    return (
      <DNAPopover>
        <DNAPopover.Anchor>
          <DNAIcon.Styled
            color={colors['color-gray-400']}
            appearance="ghost"
            status="tertiary"
            size="md"
            name="clock-time-nine-outline"
          />
        </DNAPopover.Anchor>
        <DNAPopover.Content>
          {scheduledPublish && (
            <DNAText numberOfLines={1} status="basic">
              {`This file will be published on ${formattedDate}`}
            </DNAText>
          )}
        </DNAPopover.Content>
      </DNAPopover>
    )
  }

  if (mode === 'TEXT') {
    return (
      <DNABox spacing="xs" alignY="center">
        <DNAIcon.Styled
          color={colors['color-gray-400']}
          appearance="ghost"
          status="tertiary"
          size="md"
          name="clock-time-nine-outline"
        />
        {scheduledPublish && (
          <DNAText numberOfLines={1} status="flat">
            {`This file will be published on ${formattedDate}`}
          </DNAText>
        )}
      </DNABox>
    );
  }

  return null
};

export const FieldHeader: React.FC<FieldHeaderProps & TouchableOpacityProps> = (props) => {
  const { field, sortIcon, toggleSort, variant = 'DEFAULT', ...rest } = props

  const fieldHeaderVariantConfigs: FieldHeaderVariantConfigs = {
    DEFAULT: {
      buttonStatus: 'secondary',
      buttonContent: field.fieldLabel,
    },
    ADMIN_SETTINGS: {
      buttonStatus: 'tertiary',
      buttonContent: <DNAText status="flat" label>{field.fieldLabel}</DNAText>,
    },
    HUBS: {
      buttonStatus: 'tertiary',
      buttonContent: <DNAText status="flat" label>{field.fieldLabel}</DNAText>,
    },
  }

  const currentVariant = fieldHeaderVariantConfigs[variant]

  return (
    <GridList.Header {...rest}>
      <Iffy is={field.fieldLabel.length > 0}>
        <DNAButton
          appearance="ghostLink"
          status={currentVariant.buttonStatus}
          padding="none"
          onPress={() => toggleSort(field.fieldName)}
          iconRight={sortIcon}
          testID={'header-sort-' + kebabCase(field.fieldName)}
          style={{ right: 8 }}
        >
          {currentVariant.buttonContent}
        </DNAButton>
      </Iffy>
    </GridList.Header>
  )
}

export const DocumentListRow: React.FC<DNADocumentListRowProps> = (props) => {
  const {
    documentORM,
    isDesktop,
    isOnline,
    fields,
    variant,
    cellStyle,
    rowStyle,
    onPress,
    onThumbnailPress,
    actionCallbacks,
    selectEnabled,
    checked,
    onCheck,
  } = props
  const route = useCurrentPage()
  const documentActions = useDNADocumentActions()
  const [isHovered, setIsHovered] = useState<boolean>(false);
  // [TODO-2126] - Rather than checking network status and the isContentCached key here
  //               we should just expose a property on the Version ORM that combines both
  //               Something like "isUnavailable"
  const unavailableContent =
    !isOnline &&
    (!documentORM.relations.version.cachedDocumentVersionORM?.meta.assets.isContentCached ||
      documentORM.model.type === FileType.WEB)
  const { configsMap } = documentORM.meta.customValues;

  const isPublisherRoute = route?.configOptions?.modules?.includes('publisher')

  const MemoizedActionButtons = useMemo(() => (
    <DNABox alignX="center" alignY="center">
      <Iffy is={variant === Variant.MSL}>
        <DNABox>
          <Iffy is={selectEnabled}>
            {onCheck &&
              <DNACheckbox
                context="altBg"
                status="primary"
                style={{ margin: 6 }}
                checked={!!checked}
                onChange={onCheck}
              />
            }
          </Iffy>
          <DNAButton
            appearance="ghost"
            status="gray"
            padding={isDesktop ? 'sm' : 'md'}
            rounded="md"
            onPress={documentActions.bookmark(documentORM)}
            iconLeft={documentORM.meta?.bookmark?.isBookmarked
              ? 'bookmark'
              : 'bookmark-outline'
            }
            size={isDesktop ? 'md' : 'lg'}
            style={isDesktop ? null : { width: 48, height: 48 }}
          />

        </DNABox>
      </Iffy>
      <DNADocumentContextMenu
        documentORM={documentORM}
        variant={variant}
        actionCallbacks={actionCallbacks}
      >
        <DNAButton
          appearance="ghost"
          status="gray"
          padding={isDesktop ? 'sm' : 'md'}
          rounded="md"
          iconLeft="dots-vertical"
          size={isDesktop ? 'md' : 'lg'}
          style={isDesktop ? null : { width: 48, height: 48 }}
        />
      </DNADocumentContextMenu>
    </DNABox>
  ), [documentORM, isDesktop, variant, unavailableContent, selectEnabled, checked]);

  function onMouseEnter(): void {
    setIsHovered(true);
  }

  function onMouseLeave(): void {
    setIsHovered(false);
  }
  const safeOnPress = () => {
    if (onPress && !unavailableContent) {
      onPress(documentORM)
    }
  }
  const safeOnThumbnailPress = () => {
    if (onThumbnailPress) {
      onThumbnailPress(documentORM)
    }
  }

  const getFormattedModelValue = (field: ListFieldConfig) => {
    const value = field.fieldType === 'MODEL'
      ? (field.fieldResolver
        ? field.fieldResolver(documentORM.relations.version.latestDocumentVersionORM)
        : documentORM.relations.version.latestUsableDocumentVersionORM?.model[field.fieldName])
      : documentORM.model[field.fieldName]

    switch (field.dataType) {
      case 'date':
        return value ? format(new Date(value), 'MM/dd/yyyy') : ''
      case 'boolean':
        return value ? 'Yes' : 'No'
      default:
        if (field.fieldName === 'type' && value === FileType.MP4) return 'VIDEO'
        return value;
    }
  }

  const modelFieldRenderer = (field: ListFieldConfig) => {
    function getRegularFieldComponent(
      field: ListFieldConfig): ReactElement {
      return (
        <DNAText
          numberOfLines={VALUE_MAX_TEXT_LINES}
          testID={`document-gridlist-${field.fieldLabel.toLowerCase()}`}
        >
          {getFormattedModelValue(field)}
        </DNAText>
      );
    }

    if (field.fieldName === 'status') {
      const documentVersion = documentORM.relations.version.latestDocumentVersionORM
      const scheduledPublish = documentVersion.meta.schedule.publish.scheduledAt
      return documentORM.meta.hasUnpublishedVersion
        ? (
          <DNABox spacing="xs" key={documentORM.model.id}>
            <DNAPopover >
              <DNAPopover.Anchor>
                <DNABox
                  style={util.mergeStyles(
                    undefined,
                    [
                      S.unPublishedVersionStatus,
                      (documentORM.meta.hasUnpublishedVersion && documentORM.model.status === DocumentStatus.PUBLISHED),
                    ],
                  )}
                >
                  <DNADocumentChip
                    item={documentORM}
                    variant="status"
                  />
                </DNABox>
              </DNAPopover.Anchor>
              <DNAPopover.Content offset={-60}>
                <DNAText numberOfLines={1} style={{ color: 'white' }}>New version in progress</DNAText>
              </DNAPopover.Content>
            </DNAPopover>
            <Iffy is={documentVersion.meta.schedule.publish.isScheduled}>
              <ScheduleDocumentIcon mode="ICON" scheduledPublish={scheduledPublish} />
            </Iffy>
          </DNABox>
        )
        : <DNADocumentChip
            item={documentORM}
            variant="status"
        />
    } else if (field.fieldName === 'thumbnail') {
      const documentVersion = documentORM.relations.version.latestUsableDocumentVersionORM

      const canPresent = documentORM.meta.permissions.MSLPresent
      const defaultOnPress = canPresent ? documentActions.present(documentORM) : documentActions.preview(documentORM)

      const handleThumbnailPress = () => {
        return isPublisherRoute ? safeOnThumbnailPress() : defaultOnPress()
      }

      return (
        <Pressable onPress={unavailableContent ? undefined : safeOnThumbnailPress}>
          <DNABox style={S.documentThumbnailBorder}>
            <DNADocumentThumbnail
              width={85}
              height={48}
              documentVersionORM={documentVersion}
              showProcessing={variant === Variant.publisher}
              unavailableContent={unavailableContent}
              /** Custom behavior here. Thumbs in Publisher routes just open the preview modal,
               * whereas MSL views will either present or preview based on whether a doc is internal */
              onPress={unavailableContent ? undefined : handleThumbnailPress}
            />
          </DNABox>
        </Pressable>
      )
    } else if (field.fieldName === 'labelStatusDot') {
      return (
        <DNADocumentStatusDot documentORM={documentORM} />
      )
    } else if (field.fieldName === 'title') {
      return (
        <DNABox appearance="col" fill spacing="xs" childStyle={[1, { flexDirection: 'row' }]}>
          {getRegularFieldComponent(field)}
          <Iffy is={unavailableContent}>
            <DNAChip
              appearance="tag"
              style={{ backgroundColor: luxColors.basicBlack.primary }}
              testID="content-not-available"
            >
              CONTENT NOT AVAILABLE
            </DNAChip>
          </Iffy>
        </DNABox>
      )
    } else {
      return getRegularFieldComponent(field);
    }
  }

  return (
    // @ts-ignore [todo] Extend As Props to Container Props
    <GridList.Row
      testID="document-list-row"
      as={DNACard}
      // appearance="flat"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onFocus={onMouseLeave}
      interactive="hoverable"
      onPress={safeOnPress}
      style={
        util.mergeStyles(
          undefined,
          [S.hoveredRow, isHovered],
          rowStyle,
          { opacity: unavailableContent ? 0.4 : 1, cursor: 'pointer' })
      }
    >
      {
        fields?.map(field => (
          <GridList.Col key={field.fieldName} style={cellStyle}>
            <Iffy is={field.fieldType === 'ACTION_MENU'}>
              {MemoizedActionButtons}
            </Iffy>
            <Iffy is={field.fieldType === 'MODEL' || field.fieldType === 'DOC_MODEL'}>
              {modelFieldRenderer(field)}
            </Iffy>
            <Iffy is={field.fieldType === 'LABEL'}>
              {field.isBadge
                ? <CustomFieldBadgeList
                    documentVersionORM={documentORM.relations.version.latestUsableDocumentVersionORM}
                />
                : <DNAText
                    numberOfLines={VALUE_MAX_TEXT_LINES}
                    testID={`document-gridlist-${field.fieldLabel.toLowerCase()}`}
                >
                  {configsMap?.[field.id ?? '-1']?.displayValues.join(', ')}
                </DNAText>
              }
            </Iffy>
          </GridList.Col>
        ))
      }
    </GridList.Row>
  )
}
